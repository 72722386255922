
export default {
  name: "IndexPage",
  data() {
    return {
      title: this.$t("createProfessionalStore"),
      description: this.$t("createProfessionalStoreDescription"),
      reviews: [
        {
          masses: this.$t("review1"),
          fullName: "عبدالله محمد الوصابي",
          info: this.$t("storeName1"),
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses: this.$t("review2"),
          fullName: "احمد علي",
          info: this.$t("storeName2"),
          pic: "/assets/images/flags/Flags-1.png",
        },
        {
          masses: this.$t("review3"),
          fullName: "محمد خليل",
          info: this.$t("storeName3"),
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses: this.$t("review4"),
          fullName: "عبدالله محمد الوصابي",
          info: this.$t("storeName4"),
          pic: "/assets/images/flags/Flags-2.png",
        },
        {
          masses: this.$t("review5"),
          fullName: "تركي الزهراني",
          info: this.$t("storeName5"),
          pic: "/assets/images/flags/Flags-2.png",
        },
      ],
    };
  },
  head() {
    return {
      title: this.title,
      description: this.description,
      keywords: this.$t("keywords"),
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.description,
        },
        {
          hid: "og:locale",
          property: "og:locale",
          content: "ar_AR",
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "article",
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.description,
        },
        {
          hid: "og:url",
          property: "og:url",
          content: "https://matjrah.com",
        },
        {
          hid: "og:site_name",
          property: "og:site_name",
          content: "متجرة",
        },
        {
          hid: "og:updated_time",
          property: "og:updated_time",
          content: "2022-12-10T00:08:47+03:00",
        },
        {
          hid: "og:image",
          property: "og:image",
          content: "https://matjrah.com/matjrah.png",
        },
        {
          hid: "apple-mobile-web-app-title",
          name: "apple-mobile-web-app-title",
          content: this.title,
        },
        { name: "twitter:site", content: "@Matjrah" },
        { name: "twitter:card", content: "summary_large_image" },
        {
          hid: "twitter:url",
          name: "twitter:url",
          content: "https://matjrah.com",
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: this.title,
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: this.description,
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content: "https://matjrah.com/matjrah.png",
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: "https://matjrah.com/",
        },
        {
          rel: "next",
          href: "https://matjrah.com/prices",
        },
      ],
    };
  },
  jsonld() {
    const items = this.reviews.map((item) => ({
      "@type": "Review",
      "author": { "@type": "Person", "name": item.fullName },
      "datePublished": "2022-01-01",
      "name": item.fullName,
      "reviewBody": item.masses,
      "reviewRating": {
        "@type": "Rating",
        "ratingValue": "5"
      },
    }));
    return {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "WebSite",
          "@id": "https://matjrah.com#website",
          "author": { '@id': '#identity' },
          "copyrightHolder": { '@id': '#identity' },
          "copyrightYear": '2022-10-14T10:10:00-07:00',
          "creator": { '@id': '#creator' },
          "dateModified": '2022-10-14T10:10:00-07:00',
          "datePublished": '2022-10-14T10:10:00-07:00',
          "description": this.$t("jsonldDescription"),
          "headline": "منصة متجرة",
          "image": {
            '@type': 'ImageObject',
            "url": "https://matjrah.com/matjrah.png",
          },
          "inLanguage": 'ar_AR',
          "mainEntityOfPage": "https://matjrah.com/",
          "name": "منصة متجرة",
          "publisher": { '@id': '#creator' },
          "url": "https://matjrah.com/",
        },
        {
          "@type": "Organization",
          "@id": "https://matjrah.com/#organization",
          "url": "https://matjrah.com/",
          "name": "منصة متجرة",
          "alternateName": "منصة متجرة",
          "description": this.$t("organizationDescription"),
          "logo": {
            "@type": "ImageObject",
            "url": "https://matjrah.com/matjrah.png",
          },
          "image": {
            '@type': 'ImageObject',
            "url": "https://matjrah.com/matjrah.png",
          },
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "telephone": "920033179",
              "contactType": "customer service"
            }
          ]
        },
        {
          "@type": "WebPage",
          "mainEntity": {
            "@type": "Book",
            "author": "/",
            "bookFormat": "https://schema.org/Paperback",
            "datePublished": "2022-10-01",
            "image": "https://matjrah.com/matjrah.png",
            "inLanguage": "Arabic",
            "name": "reviews",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": items.length
            },
            "review": items
          }
        }
      ]
    }
  }
};
